var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-3 pb-0",style:(("border-bottom: 1px solid " + (_vm.dark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)')))},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{class:[
          'text-decoration-none d-flex align-center route-link',
          { dark: _vm.dark } ],attrs:{"to":{
          name: _vm.AppSection.NETWORKS_MANAGEMENT,
          params: { id: _vm.applicationId },
        }}},[_c('v-icon',{attrs:{"color":_vm.dark ? 'grey lighten-3' : 'grey darken-3',"size":"20"}},[_vm._v(" mdi-chevron-left ")]),_c('span',{class:[
            'ml-1 text-body-2 grey--text',
            _vm.dark ? 'text--lighten-3' : 'text--darken-3' ]},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.goToNetworksList"))+" ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.viewNetworkTitle", _vm.networkName))+" ")])],1),(!_vm.networkIntegrations.length)?_c('v-form',{ref:"integrationForm",staticClass:"my-3",model:{value:(_vm.integrationFormIsValid),callback:function ($$v) {_vm.integrationFormIsValid=$$v},expression:"integrationFormIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('network-integration-form',{staticClass:"justify-end",attrs:{"is-horizontal":true},model:{value:(_vm.localValueIntegration),callback:function ($$v) {_vm.localValueIntegration=$$v},expression:"localValueIntegration"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.integrationFormIsValid || _vm.savingIntegrationInProgress,"loading":_vm.savingIntegrationInProgress},on:{"click":_vm.emitSave}},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.addIntegration"))+" ")])],1)],1)],1):_c('v-row',{staticClass:"mx-n3 mt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',[_vm._l((_vm.networkIntegrations),function(integration){return _c('v-tab',{key:integration.networkType,attrs:{"to":{
            name: 'networkIntegration',
            params: {
              networkIntegrationId: integration.id,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$lang( ("appAdmin.networksManagement.networkIntegration." + (integration.networkType.toLowerCase())) ))+" "),_c('v-badge',{attrs:{"dot":"","offset-x":"-4","offset-y":"4","color":_vm.getNetworkIntegrationStatusColor(integration.status)}})],1)}),_c('v-btn',{staticClass:"align-self-center ml-4",attrs:{"color":"primary","icon":"","outlined":"","x-small":""},on:{"click":function($event){_vm.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],2)],1)],1),_c('network-sidebar-form',{attrs:{"title":_vm.$lang('appAdmin.networksManagement.newIntegration'),"saving-in-progress":_vm.savingIntegrationInProgress},on:{"save":_vm.emitSave},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('network-integration-form',{model:{value:(_vm.localValueIntegration),callback:function ($$v) {_vm.localValueIntegration=$$v},expression:"localValueIntegration"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }