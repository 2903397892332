


























































































































import { Component, Vue } from "vue-property-decorator";

import NetworkSidebarForm from "./NetworkSidebarForm.vue";
import NetworkIntegrationForm from "./NetworkIntegrationForm.vue";
import NetworkResponseModel from "@/settings/models/networks-management/NetworkResponseModel";
import NetworkIntegrationRequestModel from "@/settings/models/networks-management/NetworkIntegrationRequestModel";
import NetworkIntegrationResponseModel, {
  NetworkIntegrationStatus,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    NetworkSidebarForm,
    NetworkIntegrationForm,
  },
})
export default class NetworkHeader extends Vue {
  readonly AppSection = AppSection;
  visible = false;
  localValueIntegration = new NetworkIntegrationRequestModel();
  integrationFormIsValid = true;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get network(): NetworkResponseModel | null {
    return this.$store.state.networksManagementStore.network;
  }

  get networkName(): string {
    return this.network?.networkName ?? "";
  }

  get networkIntegrations(): Array<NetworkIntegrationResponseModel> {
    return this.$store.state.networksManagementStore.networkIntegrations;
  }

  get networkIntegrationId(): number {
    return Number(this.$route.query?.networkIntegrationId);
  }

  get savingIntegrationInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingIntegrationInProgress;
  }

  getNetworkIntegrationStatusColor(status: NetworkIntegrationStatus): string {
    return {
      ACTIVE: "success",
      DISABLED: "error",
      DELETED: "",
    }[status];
  }

  emitSave(closeDialog: () => void) {
    this.localValueIntegration.networkId = (
      this.network as NetworkResponseModel
    ).id;
    this.$emit("save", {
      payload: this.localValueIntegration,
      closeDialog,
    });
  }
}
