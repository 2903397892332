import NetworkIntegrationResponseModel, {
  AuthorizationType,
  NetworkType,
} from "./NetworkIntegrationResponseModel";

export default class NetworkIntegrationRequestModel {
  constructor(
    public networkId = 0,
    public networkType = NetworkType.REVENUE,
    public authorizationType = AuthorizationType.PRE_AUTH_REQUEST,
    public deltaFrom: number | null = null,
    public deltaTo: number | null = null,
    public parentNetworkIntegrationId: number | null = null
  ) {}

  static ofSource(
    model: NetworkIntegrationResponseModel
  ): NetworkIntegrationRequestModel {
    return new NetworkIntegrationRequestModel(
      model.networkId,
      model.networkType,
      model.authorizationType,
      model.deltaFrom,
      model.deltaTo,
      model.parentNetworkIntegrationId
    );
  }
}
