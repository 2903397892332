






















import { Component, Vue } from "vue-property-decorator";

import NetworkHeader from "@/settings/components/networks-management/NetworkHeader.vue";
import NetworkResponseModel from "@/settings/models/networks-management/NetworkResponseModel";
import NetworkIntegrationRequestModel from "@/settings/models/networks-management/NetworkIntegrationRequestModel";
import NetworkIntegrationResponseModel, {
  NetworkType,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import { NetworkIntegrationFilterType } from "@/settings/models/networks-management/NetworkIntegrationFilterType";

@Component({
  components: {
    NetworkHeader,
  },
})
export default class NetworkView extends Vue {
  get isLoading(): boolean {
    return (
      this.$store.state.networksManagementStore.isLoading ||
      this.$store.state.networksManagementStore.networkIntegrationsIsLoading
    );
  }

  get networkId(): string {
    return this.$route.params.networkId;
  }

  get network(): NetworkResponseModel {
    return this.$store.state.networksManagementStore.network;
  }

  get networkIntegrations(): Array<NetworkIntegrationResponseModel> {
    return this.$store.state.networksManagementStore.networkIntegrations;
  }

  get networkIntegrationId(): number {
    return Number(this.$route.params.networkIntegrationId);
  }

  get currentNetworkIntegration(): NetworkIntegrationResponseModel | undefined {
    return this.networkIntegrations.find(
      (item) => item.id === this.networkIntegrationId
    );
  }

  async created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.networksManagement.title")
    );

    await Promise.all([
      this.$store.dispatch("loadNetworkById", this.networkId),
      this.$store.dispatch(
        "loadNetworkIntegrationsByNetworkId",
        this.networkId
      ),
    ]);

    if (!this.networkIntegrations.length) {
      this.$store.dispatch("loadShortNetworkIntegrations", {
        networkType: NetworkType.REVENUE,
        filter: NetworkIntegrationFilterType.WITHOUT_CHILDREN,
      });
    }

    if (!this.networkIntegrationId && this.networkIntegrations.length) {
      this.$router.replace({
        name: "networkIntegration",
        params: {
          networkIntegrationId: String(this.networkIntegrations[0].id),
          networkId: String(this.networkIntegrations[0].networkId),
        },
      });
    }
  }

  destroyed() {
    this.$store.commit("clearNetworksManagementData");
  }

  async handleSavingNetworkIntegration({
    payload,
  }: {
    payload: NetworkIntegrationRequestModel;
  }) {
    await this.$store.dispatch("addIntegration", payload);
    await this.$store.dispatch(
      "loadNetworkIntegrationsByNetworkId",
      this.networkId
    );
    this.$router.replace({
      name: "networkIntegration",
      params: {
        networkIntegrationId: String(this.networkIntegrations[0].id),
        networkId: String(this.networkIntegrations[0].networkId),
      },
    });
  }
}
