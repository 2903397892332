
































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import NetworkIntegrationRequestModel from "@/settings/models/networks-management/NetworkIntegrationRequestModel";
import NetworkIntegrationResponseModel, {
  AuthorizationType,
  NetworkType,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import NetworkIntegrationShortResponseModel from "@/settings/models/networks-management/NetworkIntegrationShortResponseModel";

@Component
export default class NetworkIntegrationForm extends Vue {
  @Prop() value!: NetworkIntegrationRequestModel;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop({ default: false }) isHorizontal!: boolean;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly daysRules = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.minNumber(0, this.$lang("validation.min", 0)),
  ];
  readonly authorizationTypeItems = Object.entries(AuthorizationType).map(
    ([key, value]) => ({
      text: this.$lang(`appAdmin.networksManagement.${key.toLowerCase()}`),
      value,
    })
  );

  get isRevenue(): boolean {
    return this.networkType === NetworkType.REVENUE;
  }

  get networkType(): NetworkType {
    return this.value.networkType;
  }

  set networkType(value: NetworkType) {
    const cloned = cloneDeep(this.value);
    cloned.networkType = value;

    this.$emit("input", cloned);
  }

  get parentNetworkIntegrationId(): number | null {
    return this.value.parentNetworkIntegrationId;
  }

  set parentNetworkIntegrationId(value: number | null) {
    const cloned = cloneDeep(this.value);
    cloned.parentNetworkIntegrationId = value;

    this.$emit("input", cloned);
  }

  get authorizationType(): AuthorizationType {
    return this.value.authorizationType;
  }

  set authorizationType(value: AuthorizationType) {
    const cloned = cloneDeep(this.value);
    cloned.authorizationType = value;

    this.$emit("input", cloned);
  }

  get deltaFrom(): number | null {
    return this.value.deltaFrom;
  }

  set deltaFrom(value: number | null) {
    const cloned = cloneDeep(this.value);
    cloned.deltaFrom = value;

    this.$emit("input", cloned);
  }

  get deltaTo(): number | null {
    return this.value.deltaTo;
  }

  set deltaTo(value: number | null) {
    const cloned = cloneDeep(this.value);
    cloned.deltaTo = value;

    this.$emit("input", cloned);
  }

  get networkIntegrations(): Array<NetworkIntegrationResponseModel> {
    return this.$store.state.networksManagementStore.networkIntegrations;
  }

  get networkTypeItems(): Array<{ text: string; value: string }> {
    return Object.entries(NetworkType).reduce(
      (result: Array<{ text: string; value: string }>, [key, value]: any) => {
        if (
          !this.networkIntegrations.find(
            (networkIntegration) => networkIntegration.networkType === key
          )
        ) {
          result.push({
            text: this.$lang(
              `appAdmin.networksManagement.networkIntegration.${key.toLowerCase()}`
            ),
            value,
          });
        }

        return result;
      },
      []
    );
  }

  get parentNetworks(): Array<NetworkIntegrationShortResponseModel> {
    return this.$store.state.networksManagementStore.shortNetworkIntegrations.reduce(
      (
        result: any,
        { id, networkName, networkId }: NetworkIntegrationShortResponseModel
      ) => {
        if (this.value.networkId !== networkId) {
          result.push({
            value: id,
            text: networkName,
          });
        }

        return result;
      },
      []
    );
  }

  @Watch("isRevenue")
  watchIsRevenue(value: boolean) {
    if (!value) {
      this.parentNetworkIntegrationId = null;
    }
  }
}
